import { useEffect, useRef } from "react";

import socket from "lib/socket";

export function useSocket(
  subscriptionName: string,
  effect: (payload: any) => void
) {
  const callBack = useRef(effect);
  callBack.current = effect;

  useEffect(() => {
    socket.on(subscriptionName, (payload) => {
      callBack.current(payload);
    });
    return () => {
      console.log(`socket off ${subscriptionName}`);
      socket.off(subscriptionName);
    };
  }, [subscriptionName]);
}
