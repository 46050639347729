import React from "react";

function CallRequest({
  onAnswer,
  onDeclain,
}: {
  onAnswer: () => void;
  onDeclain: () => void;
}) {
  return (
    <div className="call-request">
      <button className="green" onClick={onAnswer}>
        Answer
      </button>
      <button className="red" onClick={onDeclain}>
        Decline
      </button>
    </div>
  );
}

export default CallRequest;
