export interface ISettings {
  audio: boolean;
  video: boolean;
}

type SDPObject = {
  type: RTCSdpType;
  sdp: string | undefined;
};

export interface IVideoCall {
  mediaSettings: ISettings;
  peerConnection: RTCPeerConnection | null;
  localStream: MediaStream | null;
  remoteStream: MediaStream | null;
  getMedia: (
    localVideoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement
  ) => Promise<void>;
  endCall: (
    videoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement
  ) => void;
  createRoom: (
    localVideoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement,
    iceCandidatesToRespodent: (payload: unknown) => void
  ) => Promise<SDPObject>;
  joinRoom: (
    payload: RTCSessionDescriptionInit,
    localVideoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement,
    iceCandidatesToRespodent: (payload: unknown) => void
  ) => Promise<SDPObject>;
  calleeAnswearHandler: (payload: RTCSessionDescriptionInit) => void;
  registerCandidates: (canidates: RTCIceCandidateInit[]) => void;
}

class VideoCall implements IVideoCall {
  private static instance: VideoCall;

  private configuration = {
    iceServers: [
      {
        urls: [
          "stun:stun1.l.google.com:19302",
          "stun:stun2.l.google.com:19302",
        ],
      },
    ],
    iceCandidatePoolSize: 10,
  };

  public mediaSettings = {
    audio: true,
    video: true,
  };

  peerConnection: IVideoCall["peerConnection"] = null;
  localStream: IVideoCall["localStream"] = null;
  remoteStream: IVideoCall["remoteStream"] = new MediaStream();

  constructor(settings: ISettings) {
    if (VideoCall.instance) {
      return VideoCall.instance;
    }
    this.mediaSettings = settings;

    VideoCall.instance = this;
  }

  async getMedia(
    videoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement
  ): Promise<void> {
    try {
      this.localStream = await navigator.mediaDevices.getUserMedia(
        this.mediaSettings
      );

      videoElement.srcObject = this.localStream;

      remoteVideoElement.srcObject = this.remoteStream;
    } catch (error) {
      console.log(error);
    }
  }

  async createRoom(
    videoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement,
    iceCandidatesToRespodent: (x: unknown) => void
  ): Promise<SDPObject> {
    await this.getMedia(videoElement, remoteVideoElement);
    this.peerConnection = new RTCPeerConnection(this.configuration);
    this.registerPeerConnectionListeners();

    this.getherIceCandidates(iceCandidatesToRespodent);

    this.collectRemoteStream();

    const offer = await this.peerConnection.createOffer();

    await this.peerConnection.setLocalDescription(offer);

    const roomWithOffer = {
      type: offer.type,
      sdp: offer.sdp,
    };

    return roomWithOffer;
  }

  async joinRoom(
    offer: RTCSessionDescriptionInit,
    videoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement,
    iceCandidatesToRespodent: (x: unknown) => void
  ) {
    await this.getMedia(videoElement, remoteVideoElement);

    this.peerConnection = new RTCPeerConnection(this.configuration);

    this.registerPeerConnectionListeners();

    this.getherIceCandidates(iceCandidatesToRespodent);

    this.collectRemoteStream();

    await this.peerConnection.setRemoteDescription(offer);
    const answer = await this.peerConnection.createAnswer();
    await this.peerConnection.setLocalDescription(answer);

    const roomWithAnswer = {
      type: answer.type,
      sdp: answer.sdp,
    };

    return roomWithAnswer;
  }

  async calleeAnswearHandler(answer: RTCSessionDescriptionInit) {
    if (!this.peerConnection) return;

    if (!this.peerConnection.currentRemoteDescription && answer) {
      console.log("Set remote description: ", answer);
      const answ = new RTCSessionDescription(answer);
      await this.peerConnection.setRemoteDescription(answ);
    }
  }

  async collectRemoteStream() {
    console.log("collectTreck", this.localStream, this.peerConnection);

    if (!this.localStream || !this.peerConnection) return;

    for (const track of this.localStream.getTracks()) {
      this.peerConnection.addTrack(track, this.localStream);
    }

    this.peerConnection.addEventListener("track", (event) => {
      event.streams[0].getTracks().forEach((track) => {
        this.remoteStream?.addTrack(track);
      });
    });
  }

  registerCandidates(canidates: RTCIceCandidateInit[]) {
    canidates.forEach((cand) => {
      if (this.peerConnection) {
        const candidate = new RTCIceCandidate(cand);
        this.peerConnection.addIceCandidate(candidate);
      }
    });
  }

  private getherIceCandidates(
    iceCandidatesGetheredCallback: (payload: RTCIceCandidateInit[]) => void
  ) {
    if (!this.peerConnection) return;

    const candidates: RTCIceCandidateInit[] = [];
    this.peerConnection.addEventListener("icecandidate", (event) => {
      if (event.candidate) {
        candidates.push(event.candidate.toJSON());
      }
    });

    this.peerConnection.addEventListener("icegatheringstatechange", () => {
      if (this.peerConnection?.iceGatheringState === "complete") {
        iceCandidatesGetheredCallback(candidates);
      }
    });
  }

  registerPeerConnectionListeners() {
    if (!this.peerConnection) return;

    const peerConnection = this.peerConnection;
    this.peerConnection.addEventListener("icegatheringstatechange", () => {
      console.log(
        `ICE gathering state changed: ${peerConnection.iceGatheringState}`
      );
    });

    this.peerConnection.addEventListener("connectionstatechange", () => {
      console.log(`Connection state change: ${peerConnection.connectionState}`);
    });

    this.peerConnection.addEventListener("signalingstatechange", () => {
      console.log(`Signaling state change: ${peerConnection.signalingState}`);
    });

    this.peerConnection.addEventListener("iceconnectionstatechange ", () => {
      console.log(
        `ICE connection state change: ${peerConnection.iceConnectionState}`
      );
    });
  }

  endCall(
    videoElement: HTMLVideoElement,
    remoteVideoElement: HTMLVideoElement
  ): void {
    if (this.peerConnection) {
      this.peerConnection.ontrack = null;
      this.peerConnection.onicecandidate = null;
      this.peerConnection.oniceconnectionstatechange = null;
      this.peerConnection.onsignalingstatechange = null;
      this.peerConnection.onicegatheringstatechange = null;
      this.peerConnection.onnegotiationneeded = null;

      if (this.remoteStream) {
        this.remoteStream.getTracks().forEach((track) => track.stop());
      }

      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => track.stop());
      }

      this.peerConnection.close();
      this.peerConnection = null;

      videoElement.srcObject = null;
      remoteVideoElement.srcObject = null;
    }
  }
}

export default VideoCall;
