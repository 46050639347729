import io from "socket.io-client";

import { useEffect } from "react";

import { UserType } from "@types";

const SERVER = process.env.REACT_APP_SERVER;

const socket = io(SERVER || "http://localhost", { autoConnect: false });

export function useInitSocket(user: UserType) {
  useEffect(() => {
    if (!user) return;

    socket.auth = { userId: user.userId };
    socket.connect();

    socket.on("connect", () => {});

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [user]);
}

export default socket;
