import React, { useState } from "react";
import "./search.scss";

interface Props {
  onSubmit: (x: string) => void;
}

const Search: React.FC<Props> = ({ onSubmit }) => {
  const [value, setValue] = useState("");

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(value);
  };

  return (
    <form className="user-search" onSubmit={submitHandler}>
      <input
        className="user-search__input"
        value={value}
        placeholder="Joedoe..."
        onChange={(event) => setValue(event.target.value)}
      />
      <button className="user-search__btn" type="submit">
        Submit
      </button>
    </form>
  );
};

export default Search;
