import React from "react";
import { Link, useParams } from "react-router-dom";

import "./chats.scss";
import { Chat } from "@types";

interface Props {
  chats: Chat[];
}

const Chats: React.FC<Props> = ({ chats }) => {
  const { id } = useParams();
  return (
    <ul className="chats-list">
      {chats.map((chat) => (
        <Link
          to={`${chat._id}`}
          className={`chats-list__item ${
            chat._id === id ? "chats-list__item--active" : ""
          }`}
          key={chat._id}
        >
          {chat.name}
        </Link>
      ))}
    </ul>
  );
};

export default Chats;
