const SERVER = process.env.REACT_APP_SERVER;

type token = null | string;

export async function login(payload: { username: string; password: string }) {
  const response = await fetch(`${SERVER}/auth/login`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw response;
  }

  const data = await response.json();

  return data;
}

export async function getProfile(token: token) {
  const response = await fetch(`${SERVER}/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();

  return data;
}

export async function getContacts(token: token) {
  const response = await fetch(`${SERVER}/users/contacts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
}

export async function addContact(username: string, token: token) {
  const response = await fetch(`${SERVER}/users/contacts`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username }),
  });

  const data = await response.json();

  return data;
}

export async function getChats(token: token) {
  const response = await fetch(`${SERVER}/chats`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
}

export async function getMessages(token: token, chatId: string) {
  const response = await fetch(`${SERVER}/messages/${chatId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
}

export async function postMessage({
  token,
  chatId,
  text,
}: {
  token: token;
  chatId: string;
  text: string;
}) {
  const response = await fetch(`${SERVER}/messages`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ chatId, text }),
  });

  const data = await response.json();

  return data;
}
