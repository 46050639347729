import { useSocket } from "hooks";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import { UserType } from "@types";

import socket from "lib/socket";
import Caller, { IVideoCall } from "lib/video";

import CallRequest from "./CallRequest";
import Controls from "./Controls";
// import Video from "./Video";
import "./style.scss";

const Widget = () => {
  const selfVideo = useRef<HTMLVideoElement>(null);
  const remoteVideo = useRef<HTMLVideoElement>(null);

  const [isActiveCall, setIsActiveCall] = useState(false);
  const [CallerInstance, setCallerInstance] = useState<IVideoCall | null>(null);
  const [callRequest, setCallRequest] = useState(null);

  const params = useParams();
  const { user } = useOutletContext<{
    user: UserType;
  }>();

  useEffect(() => {
    const instance = new Caller({
      audio: true,
      video: true,
    });

    setCallerInstance(instance);
  }, []);

  useSocket("callToClient", (payload) => setCallRequest(payload.offer));

  useSocket("callAnsweardToClient", (payload) => {
    CallerInstance?.calleeAnswearHandler(payload.answer);
  });

  useSocket("candidatesToRespodent", (payload) => {
    CallerInstance?.registerCandidates(payload);
  });

  const sendIceCandidates = useCallback(
    (candidates: unknown) => {
      socket.emit("sendIceCanidatesToServer", {
        chatId: params.id,
        candidates,
      });
    },
    [params.id]
  );

  const callHandler = useCallback(async () => {
    if (!selfVideo.current || !remoteVideo.current || !CallerInstance || !user)
      return;

    setIsActiveCall(true);

    const offer = await CallerInstance.createRoom(
      selfVideo.current,
      remoteVideo.current,
      sendIceCandidates
    );

    socket.emit("callToServer", {
      chatId: params.id,
      user,
      offer,
    });
  }, [CallerInstance, params.id, sendIceCandidates, user]);

  const endCallHandler = () => {
    if (!CallerInstance || !selfVideo.current || !remoteVideo.current) return;

    CallerInstance.endCall(selfVideo.current, remoteVideo.current);
    setIsActiveCall(false);
  };

  const handleAnswer = async () => {
    if (
      !CallerInstance ||
      !selfVideo.current ||
      !remoteVideo.current ||
      !callRequest
    )
      return;

    setIsActiveCall(true);
    setCallRequest(null);

    const answer = await CallerInstance.joinRoom(
      callRequest,
      selfVideo.current,
      remoteVideo.current,
      sendIceCandidates
    );

    socket.emit("answerToServer", {
      chatId: params.id,
      answer,
    });
  };

  return (
    <>
      <div className="call-widget">
        <div
          className="call-widget__videos"
          style={{ display: isActiveCall ? "block" : "none" }}
        >
          <video className="video-container" ref={selfVideo} autoPlay muted />
          <video className="video-container" ref={remoteVideo} autoPlay muted />
        </div>
        <Controls
          startCall={callHandler}
          endCall={endCallHandler}
          isCalled={isActiveCall}
        ></Controls>
      </div>
      {callRequest && !isActiveCall && (
        <CallRequest onAnswer={handleAnswer} onDeclain={endCallHandler} />
      )}
    </>
  );
};

export default Widget;
