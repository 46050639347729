import React from "react";
import "./profile.scss";

interface Props {
  name: string;
}
const Profile: React.FC<Props> = ({ name }) => {
  return <h2 className="profile">{name}</h2>;
};

export default Profile;
