import React from "react";
import { Outlet, Navigate } from "react-router-dom";
// import { useAppContext } from "../context/AppContext";

function ProtectedRoutes() {
  const token = window.localStorage.getItem("token");
  if (!token) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default ProtectedRoutes;
