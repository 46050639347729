import React, { createContext } from "react";

import CallWidget from "../CallWidget/Widget";
import { CallState, useCallState } from "../CallWidget/hooks";
import Chat from "./Chat";
import "./style.scss";

export const ChatContext = createContext<
  [CallState, React.Dispatch<React.SetStateAction<CallState>>]
>([CallState.idle, () => null]);

const ChatContainer = () => {
  const [state, setState] = useCallState();

  return (
    <ChatContext.Provider value={[state, setState]}>
      <div className="chat-container">
        <CallWidget></CallWidget>
        <Chat></Chat>
      </div>
    </ChatContext.Provider>
  );
};

export default ChatContainer;
