import React, { useEffect, useCallback, useState, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useInitSocket } from "lib/socket";
import "./style.scss";
import Search from "./components/Search";
import Profile from "./components/Profile";
import Chats from "./components/Chats";

import { getProfile, addContact, getChats } from "api";
import { UserType, Chat, User } from "@types";

const Main = () => {
  const [user, setUser] = useState<UserType>(null);
  const [chats, setChats] = useState<Chat[]>([]);

  const token = useMemo(() => window.localStorage.getItem("token"), []);

  useInitSocket(user);

  const fetchUserData = useCallback(async () => {
    const { username, userId }: User = await getProfile(token);
    setUser({ username, userId });

    const chats = await getChats(token);
    const mappedChats = chats.map((chat: Chat) => {
      let chatName = chat.participants[0].username;

      if (chat.participants.length === 2) {
        chatName = chat.participants.filter((usr) => usr._id !== userId)[0]
          .username;
      }

      if (chat.participants.length > 2) {
        chatName = chat.participants
          .filter((usr) => usr._id !== userId)
          .slice(0, 3)
          .map((usr) => usr.username)
          .join(",");
      }

      return {
        _id: chat._id,
        participants: chat.participants,
        name: chat.name || chatName,
      };
    });

    setChats(mappedChats);
  }, [token]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const onSearch = (username: string) => {
    addContact(username, token);
    fetchUserData();
  };

  return (
    <div className="main">
      <div className="main__aside">
        <Profile name={user ? user.username : ""} />
        <Search onSubmit={onSearch} />
        <Chats chats={chats} />
      </div>
      <div className="main__chat">
        <Outlet context={{ user, chats }} />
      </div>
    </div>
  );
};

export default Main;
