import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import ProtectedRoutes from "./routes/ProtectedRoute";
import Login from "./pages/login";
import Main from "./pages/main";
import Chat from "./pages/main/components/Chat";

function App() {
  return (
    <AppProvider>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/main" element={<Main />}>
            <Route path=":id" element={<Chat />} />
          </Route>
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </AppProvider>
  );
}

export default App;
