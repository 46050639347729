import { useState } from "react";

export enum CallState {
  idle = "idle",
  connecting = "connecting",
  connected = "connected",
}

export function useCallState(): [
  CallState,
  React.Dispatch<React.SetStateAction<CallState>>
] {
  const [state, setState] = useState(CallState.idle);

  return [state, setState];
}
