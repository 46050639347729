import React from "react";

interface Props {
  startCall: () => void;
  endCall: () => void;
  isCalled: boolean;
}
const Controls: React.FC<Props> = ({ startCall, endCall, isCalled }) => {
  return isCalled ? (
    <button onClick={endCall}>End</button>
  ) : (
    <button onClick={startCall}>Call</button>
  );
};

export default Controls;
