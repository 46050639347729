import React, { useRef, useState } from "react";

export type TFileSubmitHandler = (payload: {
  text: string;
  files: FileList | null | undefined;
}) => void;

interface Props {
  onSubmit: TFileSubmitHandler;
}

const TextArea: React.FC<Props> = ({ onSubmit }) => {
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const changeHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };

  const submitHandler = () => {
    const timedValue = value.trim();
    const files = fileRef.current?.files;

    if (!value && !files?.length) return;

    onSubmit({ text: timedValue, files: files });
    setValue("");
    inputRef.current!.focus();
    fileRef.current!.value = "";
  };

  return (
    <div className="chat-input">
      <input
        type="file"
        className="chat-input__file"
        ref={fileRef}
        data-testid="file-input"
      />
      <textarea
        className="chat-input__text-area"
        ref={inputRef}
        placeholder="..."
        name="message"
        cols={30}
        rows={5}
        maxLength={300}
        onChange={changeHandler}
        value={value}
      ></textarea>
      <button
        className="chat-input__submit"
        type="submit"
        name="submit"
        onClick={submitHandler}
      >
        <svg className="chat-input__submit-icon" viewBox="0 0 48 48">
          <path d="M6 40V8l38 16Zm3-4.65L36.2 24 9 12.5v8.4L21.1 24 9 27Zm0 0V12.5 27Z" />
        </svg>
      </button>
    </div>
  );
};

export default TextArea;
