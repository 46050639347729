import React, { useContext, useState } from "react";

type tokenType = [null | string, (x: string) => void];

const AppContext = React.createContext<tokenType>([null, () => undefined]);

export function AppProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [token, setToken] = useState<string | null>(null);
  // const value = [token, setToken];
  return (
    <AppContext.Provider value={[token, setToken]}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  return context;
}
