import { useSocket } from "hooks";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutletContext, useParams } from "react-router-dom";

import {
  Chat as ChatType,
  Message as MessageType,
  Participant,
  UserType,
} from "@types";

import { getMessages } from "api";

import socket from "lib/socket";

import Message from "./Message";
import TextArea, { TFileSubmitHandler } from "./TextArea";

const CHAT_ASSETS = `${process.env.REACT_APP_PUBLIC_S3_BUCKET}/chat-assets`;

interface SocketData {
  to: Participant;
  text: string;
  from: string;
  chatId: string;
  file?: { buffer: File; filename: string; type: string };
}

const Chat = () => {
  const chatContainer = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const token = useMemo(() => window.localStorage.getItem("token"), []);
  const params = useParams();

  const { user, chats } = useOutletContext<{
    user: UserType;
    chats: ChatType[];
  }>();

  const currentChat = useMemo(
    () => chats.filter((chat) => chat._id === params.id)[0],
    [chats, params]
  );

  useEffect(() => {
    if (!chatContainer.current) return;

    chatContainer.current.scrollTo(0, 0);
  }, [messages]);

  useSocket("msgToClient", (message) => {
    setMessages((messages) => [...messages, message]);
  });

  useEffect(() => {
    socket.emit("join", params.id);

    return () => {
      socket.emit("leave", params.id);
    };
  }, [params.id]);

  const sendMessage: TFileSubmitHandler = ({ text, files }) => {
    if (!user) return;

    const [respodent] = currentChat.participants.filter(
      (participant) => participant._id !== user.userId
    );

    let message: MessageType = {
      text,
      _id: crypto.randomUUID(),
      author: { _id: user.userId, username: user.username },
    };

    if (files?.length) {
      const tempSrc = URL.createObjectURL(files[0]);
      message = { ...message, filename: files[0].name, src: tempSrc };
    }

    setMessages((messages) => [message, ...messages]);

    let socketData: SocketData = {
      to: respodent,
      text,
      from: user.userId,
      chatId: currentChat._id,
    };

    if (files?.length) {
      const file = files[0];

      socketData = {
        ...socketData,
        file: { buffer: file, filename: file.name, type: file.type },
      };
    }

    socket.emit("msgToServer", socketData);
  };

  const fetchMessages = useCallback(async () => {
    if (!token || !params.id || !currentChat) return;

    const response = await getMessages(token, params.id);

    const data: MessageType[] = response.map((message: MessageType) => ({
      _id: message._id,
      text: message.text,
      filename: message.filename,
      src: `${CHAT_ASSETS}/${currentChat._id}/${message.filename}`,
      author: {
        _id: message.author._id,
        username: message.author.username,
      },
      createdAt: message.createdAt,
      updatedAt: message.updatedAt,
    }));

    setMessages(data.reverse());
  }, [params, token, currentChat]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <>
      <div className="chat" ref={chatContainer}>
        {messages.map((message) => (
          <Message key={message._id} data={message} user={user} />
        ))}
      </div>
      <TextArea onSubmit={sendMessage} />
    </>
  );
};

export default Chat;
