import { memo } from "react";

import { Chat, Message as MessageType, UserType } from "@types";

interface Props {
  data: MessageType;
  user: UserType;
}

const Message: React.FC<Props> = ({ data, user }) => {
  const { author, text, src } = data;
  const owner = author._id === user?.userId;
  return (
    <div
      className={`message ${owner ? "message--owner" : ""}`}
      data-testid="message-wrapper"
    >
      <div className="message__wrapper">
        {src && <img className="message__image" src={src} alt="" />}
        {text && <div className="message__text">{text}</div>}
      </div>
    </div>
  );
};

export default memo(Message);
