import React, { useState } from "react";
import "./style.scss";
import { login } from "api";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [formData, updateFormData] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = await login(formData);
      window.localStorage.setItem("token", token.access_token);
      navigate("/main");
    } catch (error) {
      window.alert("Upps something went wrong!");
    }
  };

  return (
    <div className="login-page">
      <form className="login" onSubmit={handleSubmit}>
        <label className="login__label" htmlFor="username">
          Username:
          <input
            className="login__input"
            type="text"
            name="username"
            onChange={handleChange}
            value={formData["username"]}
          />
        </label>
        <label className="login__label" htmlFor="password">
          Password:{" "}
          <input
            type="password"
            name="password"
            onChange={handleChange}
            value={formData["password"]}
          />
        </label>
        <button className="login__input" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
